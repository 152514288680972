import React from 'react';
import loginImg from "../assets/img/Services/Rectangle 4622.png";
import overlayImg from "../assets/img/Login/Rectangle 4621.png";
import Footer from '../component/Footer';
import HeaderHome from '../component/HeaderHome';
import JobUpdates from '../component/JobUpdates';
import CabinetImage from '../assets/Logo/Cabinet.png';
import Logo from '../assets/Logo/logo.png';
import Sign from '../assets/Logo/Mylvaganam.png';
import Carbon from "../assets/img/Carbon/Carbon.jpg"

const CarbonFootPrint = () => {
  return (
    <>
      <HeaderHome />
    <div className="my-[60px] mx-[50px] shadow-lg rounded-xl flex flex-col justify-center">
        <div data-aos="zoom-in" className="relative w-full flex items-center justify-center">
          <div className="relative w-full h-full flex items-center justify-center">
          <div className='absolute text-[#FFFFFF] text-[20px] md:text-[30px] lg:text-[50px] z-20'>Carbon Reduction Plan</div>
            <img src={loginImg} alt="Login" className='w-full h-full' />
            <div className="absolute inset-0 bg-transparent">
              <img src={overlayImg} alt="Overlay" className="rounded-xl w-full h-full" />
            </div>
          </div>
        </div>
        <div className="mt-12 px-4 py-8">
            {/* <img data-aos="zoom-in" src={CabinetImage} alt="Cabinet image" className='w-[300px]'/>
            <div className='mt-2 flex justify-center'>
            <img data-aos="zoom-in" src={Logo} alt="Cabinet image"className='mt-5 h-24 md:h-32 lg:h-60 w-[150px] md:w-[200px] lg:w-[200px] xl:w-[400px]'/>
            </div> */}
        <p data-aos="fade-up" className="mt-5 text-center font-bold text-[20px] sm:text-[30px] md:text-[40px]">Carbon Reduction Plan</p>
        <p data-aos="zoom-in" className="mt-10 font-bold text-[20px] sm:text-[26px] md:text-[36px]">Supplier: RHC 360 Solutions</p>
        <p data-aos="zoom-in" className="mt-8 font-bold text-3">Published: 10/01/2025</p>
        <p data-aos="fade-up" className="mt-12 font-bold text-[20px] sm:text-[24px]">Commitment to achieving Net Zero</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2"><span className='font-bold text-3'>RHC 360 Solutions</span> is committed to achieving Net Zero emissions by 2040.</p>
        <p data-aos="fade-up" className="mt-12 font-bold text-[20px] sm:text-[24px]">Baseline Emissions Footprint</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">Baseline emissions are a record of the greenhouse gases that have been produced in the past and were produced prior to the introduction of any strategies to reduce emissions. Baseline emissions are the reference point against which emissions reduction can be measured.</p>
        {/* <p data-aos="zoom-in" className="mt-5 text-3 mb-2">At Ramsy Health Care, we are dedicated to delivering exceptional community and domiciliary care support services to our clients. Our highly trained workforce is committed to providing compassionate care that upholds the dignity of our service users while promoting their independence and freedom of choice. We are fully registered with Care Quality Commission (CQC). Our commitment to compassionate care extends beyond the well-being of our clients; it includes the well-being of our planet. We recognize the urgent need to address climate change and reduce our carbon footprint.</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">As part of our corporate responsibility, Ramsy Health Care is taking proactive steps to reduce our environmental impact and contribute to a more sustainable future. We have developed a comprehensive carbon reduction plan that encompasses all aspects of our operations, from energy efficiency improvements in our facilities to sustainable transportation solutions for our workforce.</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">Our goals are clear and measurable. We are working diligently to reduce our greenhouse gas emissions by [insert specific percentage or amount] by [insert target year], compared to our baseline emissions.</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">We understand that reducing our carbon emissions is not only essential for the environment but also reflects our commitment to delivering high-quality care to our clients. By embracing sustainability, we aim to create a healthier, greener future for both our clients and our planet.</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">Ramsy Health Care Ltd is proud to be a responsible corporate citizen, and we will continue to monitor and report on our progress toward carbon reduction. Together, we can make a significant impact in the fight against climate change while delivering exceptional care to those who rely on our services.</p> */}

        <table data-aos="zoom-in" className="mt-12 min-w-full bg-white border-2">
          <thead className='border-2 font-bold border-black'>
            <td className='p-2'>Baseline Year: 2023</td>
          </thead>
          <thead className='border-2 font-bold border-black'>
            <td className='p-2'>Additional Details relating to the Baseline Emissions calculations.</td>
          </thead>
          <thead className='border-2 font-bold border-black'>
            <td className='p-2'>We have selected 2023 as the foundation for our baseline emissions, recognising that this year captures a more complete and contemporary picture of our organisation’s operations and carbon footprint. Prior to this our emissions reporting was limited and did not fully encompass the breadth of categories now included within our measurement framework. Moreover, substantial organisational changes—such as the integration of new service lines, the enhancement of data management systems, and the refinement of reporting protocols—have reshaped the way we track and interpret our environmental impact.</td>
          </thead>
          <thead className='border-2 font-bold border-black'>
            <td className='p-2'>Baseline year emissions:</td>
          </thead>
          <thead className='border-2 font-bold border-black'>
            <td className='p-2 border-2 border-black'>EMISSIONS</td>
            <td className='p-2'>TOTAL (tCO2e)</td>
          </thead>
          <tbody className='border-2'>
            <tr className='border-2 font-bold'>
                <td className='p-2 border-2 border-black' rowspan="6">Scope 1</td>
                <td className='p-2 border-2 border-black'>7.93</td>
            </tr>
            {/* <tr className='border-2'>
                <td className='border-2 border-black'>0.0000</td>
            </tr>
            <tr className='border-2'>
                <td className='border-2 border-black'>0.0000</td>
            </tr>
            <tr className='border-2'>
                <td className='border-2 border-black'>0.0000</td>
            </tr>
            <tr className='border-2'>
                <td className='border-2 border-black'>0.0000</td>
            </tr>
            <tr className='border-2 font-bold'>
                <td className='border-2 border-black'>0.0000</td>
            </tr> */}

            {/* <tr className='border-2 font-bold'>
                <td className='border-2 border-black' rowspan='5'>Scope 2</td>
                <td className='border-2 border-black'>Scope 2 – Indirect Emissions</td>
                <td className='border-2 border-black'>tCO2e</td>
            </tr>
            <tr className='border-2'>
                <td className='border-2 border-black'>Electricity (Head office & Branches)</td>
                <td className='border-2 border-black'>0.0000</td>
            </tr>
            <tr className='border-2'>
                <td className='border-2 border-black'>Gas (Heating type)</td>
                <td className='border-2 border-black'>0.0000</td>
            </tr>
            <tr className='border-2'>
                <td className='border-2 border-black'>Water</td>
                <td className='border-2 border-black'>0.0000</td>
            </tr>
            <tr className='border-2 font-bold'>
                <td className='border-2 text-end border-black'>Scope 2 Total</td>
                <td className='border-2 border-black'>0.0000</td>
            </tr>
          
            <tr className='border-2 font-bold'>
                <td className='border-2 border-black' rowspan="8">Scope 3 (Included Sources)</td>
                <td className='border-2 border-black'>Scope 3</td>
                <td className='border-2 border-black'>tCO2e</td>
            </tr>
            <tr className='border-2'>
                <td className='border-2 border-black'>Waste Generated in Operations</td>
                <td className='border-2 border-black'>3.1281</td>
            </tr>
            <tr className='border-2'>
                <td className='border-2 border-black'>Upstream transportation & distribution</td>
                <td className='border-2 border-black'>0.0000</td>
            </tr>
            <tr className='border-2'>
                <td className='border-2 border-black'>Downstream transportation & distribution</td>
                <td className='border-2 border-black'>0.0000</td>
            </tr>
            <tr className='border-2'>
                <td className='border-2 border-black'>Business Travel</td>
                <td className='border-2 border-black'>0.0000</td>
            </tr>
            <tr className='border-2'>
                <td className='border-2 border-black'>Commuting (round trip to location)</td>
                <td className='border-2 border-black'>0.0000</td>
            </tr>
            <tr className='border-2'>
                <td className='border-2 border-black'>Work From Home</td>
                <td className='border-2 border-black'>0.0000</td>
            </tr>
            <tr className='border-2 font-bold'>
                <td className='border-2 text-end border-black'>Scope 3 Total</td>
                <td className='border-2 border-black'>3.1281</td>
            </tr> */}
          </tbody>
          <thead className='border-2 border-black font-bold'>
            <td className='p-2 border-2 border-black'>Scope 2</td>
            <td className='p-2'>2.79</td>
          </thead>
          <thead className='border-2 border-black font-bold'>
            <td className='p-2 border-2 border-black'>Scope 3 (Included Sources)</td>
            <td className='p-2 flex flex-col'>26.87<span className='mt-4'>The sources we included in our Scope 3 emissions are:
            <ul data-aos="zoom-in" className='mt-5 ml-10 list-disc'>
            <li className='mb-2'>Water and Waste</li>
            <li className='mb-2'>Waste Generated</li>
            <li className='mb-2'>Employee Commuting (Estimated based on ONS data, average employee numbers and assumed that all employees are using an average sized car)</li>
            </ul>
</span></td>
          </thead>
          <thead className='border-2 border-black font-bold'>
            <td className='p-2 border-2 border-black'>Total Emissions</td>
            <td className='p-2'>38.21</td>
          </thead>
        </table>

    
        <p data-aos="fade-up" className="mt-12 font-bold text-[20px] sm:text-[24px]">Current Emissions Reporting</p>
        <table data-aos="zoom-in" className="mt-5 min-w-full bg-white border-2 border-black">
          <thead className='border-2 font-bold border-black'>
            <td className='p-2'>Reporting Year: 2024</td>
          </thead>
          <thead className='border-2 font-bold border-black'>
            <td className='p-2 border-2 border-black'>EMISSIONS</td>
            <td className='p-2'>TOTAL (tCO2e)</td>
          </thead>
          <thead className='border-2 font-bold border-black'>
            <td className='p-2 border-2 border-black'>Scope 1</td>
            <td className='p-2'>8.14</td>
          </thead>
          <thead className='border-2 font-bold border-black'>
            <td className='p-2 border-2 border-black'>Scope 2</td>
            <td className='p-2'>3.09</td>
          </thead>
          <thead className='border-2 font-bold border-black'>
            <td className='p-2 border-2 border-black'>Scope 3 (Included Sources)</td>
            <td className='p-2'>39.69</td>
          </thead>
          {/* <tbody className='border-2 border-black'>
            <tr className='border-2 font-bold'>
                <td className='border-2 border-black' rowspan="6">Scope 1</td>
                <td className='border-2 border-black'>Scope 1 – Direct Emissions</td>
                <td className='border-2 border-black'>tCO2e</td>
            </tr>
            <tr className='border-2 border-black'>
                <td className='border-2 border-black'>Stationary Combustion (Boiler, Generator)</td>
                <td className='border-2 border-black'>0.0000</td>
            </tr>
            <tr className='border-2'>
                <td className='border-2 border-black'>Mobile Combustion (Company Fleet)</td>
                <td className='border-2 border-black'>0.0000</td>
            </tr>
            <tr className='border-2'>
                <td className='border-2 border-black'>Process Emissions (On-Site Manufacturing)</td>
                <td className='border-2 border-black'>0.0000</td>
            </tr>
            <tr className='border-2'>
                <td className='border-2 border-black'>Fugitive Emissions (F-Gasses)</td>
                <td className='border-2 border-black'>0.0000</td>
            </tr>
            <tr className='border-2 font-bold'>
                <td className='border-2 text-end border-black'>Scope 1 Total</td>
                <td className='border-2 border-black'>0.0000</td>
            </tr>

            <tr className='border-2 font-bold'>
                <td className='border-2 border-black' rowspan='5'>Scope 2</td>
                <td className='border-2 border-black'>Scope 2 – Indirect Emissions</td>
                <td className='border-2 border-black'>tCO2e</td>
            </tr>
            <tr className='border-2'>
                <td className='border-2 border-black'>Electricity (Head office & Branches)</td>
                <td className='border-2 border-black'>0.0000</td>
            </tr>
            <tr className='border-2'>
                <td className='border-2 border-black'>Gas (Heating type)</td>
                <td className='border-2 border-black'>0.0000</td>
            </tr>
            <tr className='border-2'>
                <td className='border-2 border-black'>Water</td>
                <td className='border-2 border-black'>0.0000</td>
            </tr>
            <tr className='border-2 font-bold'>
                <td className='border-2 text-end border-black'>Scope 2 Total</td>
                <td className='border-2 border-black'>0.0000</td>
            </tr>
          
            <tr className='border-2 font-bold'>
                <td className='border-2 border-black' rowspan="8">Scope 3 (Included Sources)</td>
                <td className='border-2 border-black'>Scope 3</td>
                <td className='border-2 border-black'>tCO2e</td>
            </tr>
            <tr className='border-2'>
                <td className='border-2 border-black'>Waste Generated in Operations</td>
                <td className='border-2 border-black'>3.1281</td>
            </tr>
            <tr className='border-2'>
                <td className='border-2 border-black'>Upstream transportation & distribution</td>
                <td className='border-2 border-black'>0.0000</td>
            </tr>
            <tr className='border-2'>
                <td className='border-2 border-black'>Downstream transportation & distribution</td>
                <td className='border-2 border-black'>0.0000</td>
            </tr>
            <tr className='border-2'>
                <td className='border-2 border-black'>Business Travel</td>
                <td className='border-2 border-black'>0.0000</td>
            </tr>
            <tr className='border-2'>
                <td className='border-2 border-black'>Commuting (round trip to location)</td>
                <td className='border-2 border-black'>0.0000</td>
            </tr>
            <tr className='border-2'>
                <td className='border-2 border-black'>Work From Home</td>
                <td className='border-2 border-black'>0.0000</td>
            </tr>
            <tr className='border-2 font-bold'>
                <td className='border-2 text-end border-black'>Scope 3 Total</td>
                <td className='border-2 border-black'>3.1281</td>
            </tr>
          </tbody> */}
          <thead className='font-bold'>
            <td className='p-2 border-2 border-black'>Total Emissions</td>
            <td className='p-2'>50.92</td>
          </thead>
        </table>

        <p data-aos="fade-up" className="mt-12 font-bold text-[20px] sm:text-[24px]">Emissions reduction targets</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">In order to continue our progress to achieving Net Zero, we have adopted the following carbon reduction targets. We project that carbon emissions will decrease over the next five years to 21.24 tCO2e by 2030. This is a reduction of 44.44%.</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">Progress against these targets can be seen in the graph below:</p>

        <div className="mt-8 w-full h-auto sm:w-3/4 sm:h-auto md:w-1/2 lg:w-2/3 mx-auto">
        <img src={Carbon} alt="table" className="w-full h-full" />
        </div>

        {/* <p data-aos="fade-up" className="mt-4 font-bold text-[20px] sm:text-[24px]">Step 1: Assess Current Emissions</p>

        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">Ramsy Health Care will start by assessing its current carbon emissions. This includes identifying sources of emissions, such as:</p>
        <ul data-aos="zoom-in" className='mt-5 ml-5 text-3'>
            <li className='mb-2'><span className='font-bold text-3'>1. Energy Consumption:</span> Calculate emissions from electricity, heating, cooling, and other energy sources used in our facilities.</li>
            <li className='mb-2'><span className='font-bold text-3'>2. Transportation:</span> Measure emissions from company vehicles, staff commuting, and any other transportation-related activities.</li>
            <li className='mb-2'><span className='font-bold text-3'>3. Supply Chain:</span> Assess emissions related to the procurement of supplies, equipment, and any outsourced services.</li>
            <li className='mb-2'><span className='font-bold text-3'>4. Waste Management:</span> Evaluate emissions from waste disposal and recycling processes.</li>
            <li className='mb-2'><span className='font-bold text-3'>5. Water Usage:</span> Consider the carbon footprint associated with water usage, especially if it involves energy-intensive processes.</li>
        </ul>


        <p data-aos="fade-up" className="mt-8 font-bold text-[20px] sm:text-[24px]">Step 2: Set Emission Reduction Targets</p>

        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">Based on the emissions assessment, Ramsy Health Care will establish emission reduction targets. These targets are aligned with the organization's mission and values, as well as industry benchmarks. Here are some potential targets:</p>
        <ul data-aos="zoom-in" className='mt-5 ml-5 text-3'>
            <li className='mb-2'><span className='font-bold text-3'>1.	Absolute Emission Reduction:</span> Reduce Scope 1 and Scope 2 emissions by a specific percentage (e.g., 20%) by a target year 2025, compared to a baseline year.</li>
            <li className='mb-2'><span className='font-bold text-3'>2.	Intensity Reduction:</span> Decrease emissions per unit of service provided (e.g., per patient cared for) to improve efficiency.</li>
            <li className='mb-2'><span className='font-bold text-3'>3.	Renewable Energy Transition:</span> Transition to a certain percentage of renewable energy sources for our facilities by 2040 to reduce Scope 2 emissions.</li>
            <li className='mb-2'><span className='font-bold text-3'>4.	Supply Chain Sustainability:</span> Collaborate with suppliers to reduce the carbon footprint of the supply chain by using sustainable and low-carbon materials and services.</li>
        </ul>

        <p data-aos="fade-up" className="mt-8 font-bold text-[20px] sm:text-[24px]">Step 3: Implement Reduction Strategies</p>

        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">To achieve these targets, Ramsy Health Care will implement a range of strategies, such as:</p>
        <ul data-aos="zoom-in" className='mt-5 ml-5 text-3'>
            <li className='mb-2'><span className='font-bold text-3'>1.	Energy Efficiency:</span> Upgrade facilities with energy-efficient lighting, heating, and cooling systems. We will work with the landlord by advising and encourage our staff to minimize energy consumption.</li>
            <li className='mb-2'><span className='font-bold text-3'>2.	Transportation Optimization:</span> Promote carpooling, remote work options, and the use of public transportation for staff commuting. Invest in a more efficient company vehicle fleet in the near future.</li>
            <li className='mb-2'><span className='font-bold text-3'>3.	Renewable Energy:</span> Invest in on-site renewable energy generation, like solar panels or wind turbines, to reduce reliance on fossil fuels.</li>
            <li className='mb-2'><span className='font-bold text-3'>4.	Waste Reduction:</span> Implement waste reduction and recycling programs to minimize waste sent to landfills.</li>
            <li className='mb-2'><span className='font-bold text-3'>5.	Employee Engagement:</span> Educate and engage employees in sustainability practices to foster a culture of environmental responsibility.</li>
        </ul>

        <p data-aos="fade-up" className="mt-8 font-bold text-[20px] sm:text-[24px]">Step 4: Monitor, Report, and Improve</p>

        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">Regularly monitor and report progress toward achieving emission reduction targets. Adjust strategies as needed to ensure that the organization is on track. Consider third-party verification of emissions data to enhance credibility.</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">Remember that carbon reduction is an ongoing process. Ramsy Health Care should continuously seek opportunities to reduce its carbon footprint while delivering exceptional care services to its clients.</p> */}


        {/* <p data-aos="zoom-in" className="mt-12 text-3 mb-2">In order to continue our progress to achieving Net Zero, we have adopted the following carbon reduction targets.</p>
        <p data-aos="zoom-in" className="text-3 mb-2">We project that carbon emissions will decrease over the next five years to XX tCO2e by 20XX. This is a reduction of XX%</p> */}

        <p data-aos="fade-up" className="mt-12 font-bold text-[20px] sm:text-[24px]">Carbon Reduction Projects</p>
        {/* <p data-aos="zoom-in" className="mt-5 text-3 mb-2">Completed Carbon Reduction Initiatives</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">The following environmental management measures and projects have been completed or implemented since the 20XX baseline. The carbon emission reduction achieved by these schemes equate to XX tCO2e, a XX%ge reduction against the 20XX baseline and the measures will be in effect when performing the contract.</p> */}
        <p data-aos="zoom-in" className="mt-5 text-3 font-bold mb-2">Completed Carbon Reduction Initiatives</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">The following environmental management measures and projects have been completed or implemented since the 2024 baseline. The carbon emission reduction achieved by these schemes equates to 4.25 tCO₂e, a 11.11% change against the 2024 baseline. Despite an overall increase in emissions due expanded operations, we have implemented measures that have mitigated potential higher emissions. These measures will continue to be in effect during the contract's performance, and we are committed to further initiatives aimed at achieving net-zero emissions by 2040.</p>

        <ul data-aos="zoom-in" className='mt-5 ml-10 text-3 list-disc'>
            <li className='mb-2'><span className='font-bold text-3'>Green certifications: </span> achieved ISO 14001 to formalise environmental management systems and demonstrate commitment to sustainability.</li>
            <li className='mb-2'><span className='font-bold text-3'>Transition to remote operations:</span> Adopted online platforms for candidate interviews and client meetings, significantly reducing travel-related emissions.</li>
            <li className='mb-2'><span className='font-bold text-3'>Energy-efficient office equipment:</span> Upgraded to energy-efficient computers, printers, and lighting systems, decreasing electricity consumption.</li>
            <li className='mb-2'><span className='font-bold text-3'>Paperless processes:</span> Implemented digital documentation and e-signatures, reducing paper usage and associated waste.</li>
            <li className='mb-2'><span className='font-bold text-3'>Waste management programmes: </span> Established comprehensive recycling systems within the office to minimise landfill contributions.</li>
            <li className='mb-2'><span className='font-bold text-3'>Flexible working policies: </span> Introduced flexible working arrangements, allowing staff to work from home, thereby reducing commuting emissions.</li>
            <li className='mb-2'><span className='font-bold text-3'>Sustainable office supplies: </span> Sourced office materials from environmentally friendly suppliers to lower the carbon footprint of procurement activities.</li>
        </ul>


        <p data-aos="zoom-in" className="mt-10 text-3 mb-2">In the future we hope to implement further measures such as:</p>

        <ul data-aos="zoom-in" className='mt-5 ml-10 text-3 list-disc'>
            <li className='mb-2'><span className='font-bold text-3'>Renewable energy procurement: </span> Plan to switch to 100% renewable energy sources for office operations by 2030, aiming to reduce Scope 2 emissions.</li>
            <li className='mb-2'><span className='font-bold text-3'>Eco-friendly office spaces:</span> Relocate to or retrofit existing offices with energy-efficient infrastructure, including improved insulation and sustainable building materials.</li>
            <li className='mb-2'><span className='font-bold text-3'>Sustainable transportation incentives: </span> Encourage the use of public transport, cycling, or electric vehicles among employees through incentive programs.</li>
            <li className='mb-2'><span className='font-bold text-3'>Carbon offsetting programs: </span> Invest in verified carbon offset projects, such as tree planting initiatives, to compensate for unavoidable emissions.</li>
            <li className='mb-2'><span className='font-bold text-3'>Supply chain engagement: </span> Collaborate with suppliers and partners to ensure they adhere to sustainable practices, amplifying the impact across the value chain.</li>
            <li className='mb-2'><span className='font-bold text-3'>Employee training and awareness:</span> Conduct regular training sessions to educate staff on best practices for reducing carbon footprints both professionally and personally.</li>
            <li className='mb-2'><span className='font-bold text-3'>Digital platform optimisation: </span> Enhance the efficiency of digital platforms and servers to reduce energy consumption associated with online operations.</li>
            <li className='mb-2'><span className='font-bold text-3'>Sustainable event management: </span> Organize virtual or eco-friendly events to minimize the environmental impact of company gatherings and conferences.</li>
            <li className='mb-2'><span className='font-bold text-3'>Regular carbon footprint assessments: </span> Commit to annual assessments of the agency's carbon footprint to monitor progress and identify areas for further improvement.</li>
        </ul>

        {/* <p data-aos="fade-up" className="mt-12 font-bold text-[20px] sm:text-[24px]">1. ISO 14001 Environmental Management System (EMS):</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">ISO 14001 is an internationally recognized standard for establishing and maintaining an effective Environmental Management System. Ramsy Health Care management is seeking to get certified for ISO 14001 in the coming years. The plan is pending board approval.</p>

        <p data-aos="fade-up" className="mt-3 ml-5 font-bold text-[20px] sm:text-[24px]">1. Certification:</p>
        <ul data-aos="zoom-in" className='mt-3 ml-10 list-disc text-3'>
            <li>Seek ISO 14001 certification through a recognized certification body.</li>
        </ul>

        <p data-aos="fade-up" className="mt-12 font-bold text-[20px] sm:text-[24px]">2. PAS 2060 Carbon Neutrality Certification:</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">PAS 2060 is a standard for achieving carbon neutrality, which means the organization takes steps to reduce its carbon emissions and offset any remaining emissions. Incorporating ISO 14001 and PAS 2060 into Ramsy Health Care's operations will help us establish a robust environmental management system and demonstrate our commitment to environmental sustainability and carbon neutrality. With these certifications we can also enhance the company's reputation and competitiveness in the healthcare industry, attracting environmentally conscious clients and partners.</p> */}

        <p data-aos="fade-up" className="mt-12 font-bold text-[24px] sm:text-[36px]">Declaration and Sign Off</p>

        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">This Carbon Reduction Plan has been completed in accordance with PPN 06/21 and associated guidance and reporting standard for Carbon Reduction Plans.</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">Emissions have been reported and recorded in accordance with the published reporting standard for Carbon Reduction Plans and the GHG Reporting Protocol corporate standard  and uses the appropriate Government emission conversion factors for greenhouse gas company reporting2.</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">Scope 1 and Scope 2 emissions have been reported in accordance with SECR requirements, and the required subset of Scope 3 emissions have been reported in accordance with the published reporting standard for Carbon Reduction Plans and the Corporate Value Chain (Scope 3) Standard3.</p>
        <p data-aos="zoom-in" className="mt-5 text-3 mb-2">This Carbon Reduction Plan has been reviewed and signed off by the board of directors (or equivalent management body).</p>

        <p data-aos="fade-up" className="mt-10 font-bold text-[16px] sm:text-[24px] mb-2">Signed on behalf of the Supplier</p>
        <table data-aos="zoom-in" className="mt-5 min-w-full bg-white border-2 border-black">
          <thead className='border-2 font-bold border-black'>
            <td className='p-4 border-2 border-black'>Signature</td>
            <td className='p-4'><img data-aos="zoom-in" src={Sign} alt="Signature"/></td>
          </thead>
          <thead className='border-2 font-bold border-black'>
            <td className='p-4 border-2 border-black'>Name</td>
            <td className='p-4'>Mylvaganam Mahadevan</td>
          </thead>
          <thead className='font-bold'>
            <td className='p-4 border-2 border-black'>Date</td>
            <td className='p-4'>10/01/2025</td>
          </thead>
        </table>
        {/* <img data-aos="zoom-in" src={Sign} alt="Signature" className='mt-8'/>
        <p data-aos="zoom-in" className="font-bold text-[12px] sm:text-[20px] mb-2">Mylvaganam Mahadevan ( Director )</p>
        <p data-aos="zoom-in" className="mt-8 font-bold text-[12px] sm:text-[20px] mb-2">Date: 25/05/2024</p> */}


        <p data-aos="fade-up" className="mt-[400px] font-bold text-[8px] sm:text-[24px] mb-2 hover:underline"><a href="https://ghgprotocol.org/corporate-standard">1https://ghgprotocol.org/corporate-standard</a></p>
        <p data-aos="fade-up" className="mt-4 font-bold text-[8px] sm:text-[24px] mb-2 hover:underline"><a href="https://www.gov.uk/government/collections/government-conversion-factors-for-company-reporting">2https://www.gov.uk/government/collections/
        government-conversion-factors-for-company-reporting</a></p>
        <p data-aos="fade-up" className="mt-4 font-bold text-[8px] sm:text-[24px] mb-2 hover:underline"><a href="https://ghgprotocol.org/standards/scope-3-standard">3https://ghgprotocol.org/standards/scope-3-standard</a></p>
        </div>
      </div>
      
      <div className="mx-[40px] my-[50px]">
        <JobUpdates/>
        </div>
      <Footer />
    </>
  );
};

export default CarbonFootPrint;
